import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import emergency2Image from '../../Assets/Images/emergency2.png';
import i18next from 'i18next';

const EmergencyModal = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
        <Button aria-label={`${i18next.resolvedLanguage === 'en' ? "emergency" : "spoed"}`} className="emergencyHeader" variant="outline" size="sm" onClick={handleShow}>
            <img src={emergency2Image} alt="emergency" style={{height: '3rem'}}/>
                {t('emergency')}
        </Button>
            <Modal show={show} size="lg" onHide={handleClose}>
                 <Modal.Header closeButton>
                    <Modal.Title>{t('emergencyTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="emergencyModal">
                    <p>{t('emergencyIntro')}</p>    
                    <a  aria-label={`${i18next.resolvedLanguage === 'en' ? "call us" : "bel ons"}`}  className="callButtonEmergency" href="tel:+31464336552"> {t("call us")} </a>
                    <h6 className="emergencySubtitle">{t('emergencyClosed')}</h6>
                    <p>{t("emergencyClosedText")}</p>
                    <a aria-label={`${i18next.resolvedLanguage === 'en' ? "call emergency practice" : "spoed bellen"}`}  className="callButtonEmergency" href="tel:+31463030360"> {t("callEmergency")} </a>
                    <p className="emergencySubtitle">{t('emergencyMoreInfo')}</p>
                </Modal.Body>
                <Modal.Footer>
                        <Button aria-label={`${i18next.resolvedLanguage === 'en' ? "close" : "sluiten"}`} variant="outline" onClick={handleClose}>
                                {t('close')}
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EmergencyModal;