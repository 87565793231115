import i18n from "i18next";
import resources from './translations.json'; 
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next) 
  .init({
    resources,
    lng: 'nl',
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    interpolation: {
        escapeValue: false 
      }
  });

export default i18n;