import { useTranslation } from "react-i18next";
import ScrollAnimation from 'react-animate-on-scroll';
import ImageSwiper from "../cells/ImageSwiper";


const AboutSection = () => {
    const { t } = useTranslation();

    return (
        <div id="aboutPractice">
                    <div className="aboutSection">
                        <div className="practiceDetails">
                            <div className="practiceAbout">
                                <ScrollAnimation animateIn="slideInUp" offset={100} animateOnce>
                                    <p className="h5 aboutTitle"> {t("brieflyAbout")} </p>
                                    <span className="aboutText">
                                        {t("brieflyFirst")}
                                    </span>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="slideInUp" offset={100} animateOnce>
                                    <span className="aboutText">
                                        {t("brieflySecond")}
                                    </span>
                                </ScrollAnimation>
                            </div>
                            <div className="">
                                <ScrollAnimation animateIn="slideInUp" offset={10} animateOnce>
                                    <ImageSwiper/>
                               </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default AboutSection;