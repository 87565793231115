import { useTranslation } from 'react-i18next';
const OverlayProtheses = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div className="overlaySubtitle">
                {t('prosthesesSub1')}
            </div>
            <div className="overlayText">
                {t('prosthesesText1')} 
            </div>
            <div className="overlaySubtitle">
                {t('prosthesesSub2')}
            </div>
            <div className="overlayText">
                {t('prosthesesText2')} 
            </div>
            <div className="overlaySubtitle">
                {t('prosthesesSub3')}
            </div>
            <div className="overlayText">
                {t('prosthesesText3')} 
            </div>
            <div className="overlaySubtitle">
                {t('prosthesesSub4')}
            </div>
            <div className="overlayText">
                {t('prosthesesText4')} 
            </div>
            <div className="overlaySubtitle">
                {t('prosthesesSub5')}
            </div>
            <div className="overlayText">
                {t('prosthesesText5')} 
            </div>
            <div className="overlaySubtitle">
                {t('prosthesesSub6')}
            </div>
            <div className="overlayText">
                {t('prosthesesText6')} 
            </div>
            <div className="overlaySubtitle">
                {t('prosthesesSub7')}
            </div>
            <div className="overlayText">
                {t('prosthesesText7')} 
            </div>
        </div>
    )
}

export default OverlayProtheses;