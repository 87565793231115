const TeamMember = (props) => {
    return (
        <div className="teamMemberContainer">
            <img src={props.image} alt="team member" className="teamMemberImage"/>
            <div className="teamMemberInfo">
                <p className="teamMemberName">{props.name}</p>
                <p className="teamMemberFunction">{props.function}</p>
                <p className="teamMemberDescr">{props.description}</p>
            </div>
            
        </div>
    )
}

export default TeamMember;