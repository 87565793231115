import React from 'react';
import './App.css';
import HeaderMenu from './components/species/HeaderMenu';
import Content from './components/species/Content';
import "./i18n";

function App() {

  return (
    <div className="App">
      <HeaderMenu/>
      <Content />
    </div>
  );
}

export default App;
