import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import implantsImage from '../../Assets/Images/implants.webp';
import invisalignImage from '../../Assets/Images/braces.webp';
import cariesImage from '../../Assets/Images/caries.webp';
import crownsImage from '../../Assets/Images/crowns.webp';
import pediatricImage from '../../Assets/Images/pediatric.webp';
import anxietyImage from '../../Assets/Images/anxiety.webp';
import prosthesesImage from '../../Assets/Images/protheses.webp';
import hygieneImage from '../../Assets/Images/hygiene.webp';
import cosmeticImage from '../../Assets/Images/cosmetic.webp';
import roothImage from '../../Assets/Images/root.webp';
import whiteningImage from '../../Assets/Images/whitening.webp';
import grindingImage from '../../Assets/Images/grinding.webp';
import ScrollAnimation from 'react-animate-on-scroll';
import i18next from 'i18next';
import OverlayAligners from '../cells/OverlayAligners';
import OverlayAnxious from '../cells/OverlayAnxious';
import OverlayCosmetic from '../cells/OverlayCosmetic';
import OverlayCrowns from '../cells/OverlayCrowns';
import OverlayGeneral from '../cells/OverlayGeneral';
import OverlayGrinding from '../cells/OverlayGrinding';
import OverlayHygiene from '../cells/OverlayHygiene';
import OverlayImplants from '../cells/OverlayImplants';
import OverlayPediatric from '../cells/OverlayPediatric';
import OverlayProtheses from '../cells/OverlayProtheses';
import OverlayRooth from '../cells/OverlayRooth';
import OverlayWhite from '../cells/OverlayWhite';

const Treatments = () => {

    const {t} = useTranslation();

    
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [currentOverlay, setCurrentOverlay] = useState('');
    

    interface ToggleProps {
        overlay: string;
    }

    const toggleOverlay = (props: ToggleProps) => {
        const overlayElement = document.getElementById('overlay');

        setCurrentOverlay(props.overlay)

        if (overlayElement === null) return;
        overlayElement.classList.contains('open') ? overlayElement.classList.remove('open') : overlayElement.classList.add('open');
 
        setOverlayOpen(!overlayOpen);
    }

    return (
        <div id="treatments">
            <div id="overlay" className="overlay">
                <div className="overlayContainer">
                    <div className="overlayHeader">
                        <span id="overlayTitle">
                            {t(currentOverlay)}
                        </span>
                        <span className="overlayClose" onClick={() => toggleOverlay({overlay:''})}></span>
                    </div>
                    <div id="overlayText">
                        {currentOverlay === 'aligners' && <OverlayAligners/>}
                        {currentOverlay === 'anxiousPatients' &&<OverlayAnxious/>}
                        {currentOverlay === 'cosmeticDentistry' &&<OverlayCosmetic/>}
                        {currentOverlay === 'crownsBridges' &&<OverlayCrowns/>}
                        {currentOverlay === 'generalDentistry' && <OverlayGeneral/>}
                        {currentOverlay === 'grindingClenching' &&<OverlayGrinding/>}
                        {currentOverlay === 'hygiene' &&<OverlayHygiene/>}
                        {currentOverlay === 'implants' &&<OverlayImplants/>}
                        {currentOverlay === 'pediatricDentistry' &&<OverlayPediatric/>}
                        {currentOverlay === 'prostheses' &&<OverlayProtheses/>}
                        {currentOverlay === 'roothTreatment' &&<OverlayRooth/>}
                        {currentOverlay === 'whithening' &&<OverlayWhite/>}
                    </div>
                </div>
            </div>
            <ScrollAnimation animateIn="slideInUp" offset={10} animateOnce className="specsTitleContainer">
                <div className="specsTitle h4">
                    {t("knowledgeTitle")}
                </div>
            </ScrollAnimation>
            <div className="">
                <div className="containerTreatments">

                    <div className="gridTreatments">
                        
                        <button aria-label={`${i18next.resolvedLanguage === 'en' ? "general dentistry" : "algemene tandheelkunde"}`} className="boxTreatment box1" onClick={() => toggleOverlay({overlay: "generalDentistry"})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="general dentistry" src={cariesImage}/>
                                {t("generalDentistry")} 
                            </p>
                            <span className="boxArrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </button>
                         <button aria-label={`${i18next.resolvedLanguage === 'en' ? "anxious patients" : "angsttandarts"}`} className="boxTreatment box2" onClick={() => toggleOverlay({overlay:'anxiousPatients'})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="anxiety dentist" src={anxietyImage}/>
                                {t("anxiousPatients")}
                            </p>
                            <span className="boxArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                         </button>
                       
                
                       <button aria-label={`${i18next.resolvedLanguage === 'en' ? "cosmetic dentistry" : "Cosmetische tandheelkunde"}`} className="boxTreatment box3" onClick={() => toggleOverlay({overlay:'cosmeticDentistry'})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="cosmetic dentistry" src={cosmeticImage}/>
                                {t("cosmeticDentistry")}
                            </p>
                            <span className="boxArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </button>
                       
                        <button aria-label={`${i18next.resolvedLanguage === 'en' ? "pediatric dentistry" : "Kindertandheelkunde"}`} className="boxTreatment box4" onClick={() => toggleOverlay({overlay:'pediatricDentistry'})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="pediatric dentistry" src={pediatricImage}/>
                                {t("pediatricDentistry")}
                            </p>
                            <span className="boxArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </button>
                        <button aria-label={`${i18next.resolvedLanguage === 'en' ? "crowns and bridges" : "Kronen en bruggen"}`} className="boxTreatment box5" onClick={() => toggleOverlay({overlay:'crownsBridges'})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="crowns and bridges" src={crownsImage}/>
                                {t("crownsBridges")}
                            </p>
                            <span className="boxArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </button>
                      
                    
                        <button aria-label={`${i18next.resolvedLanguage === 'en' ? "aligners" : "Onzichtbare beugel"}`} className="boxTreatment box6" onClick={() => toggleOverlay({overlay:'aligners'})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="aligners" src={invisalignImage}/>
                                {t("aligners")}
                                </p>
                            <span className="boxArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </button>
                        <button aria-label={`${i18next.resolvedLanguage === 'en' ? "whitening" : "Tanden bleken"}`} className="boxTreatment box7" onClick={() => toggleOverlay({overlay:'whithening'})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="whitening" src={whiteningImage}/>
                                {t("whithening")}
                            </p>
                            <span className="boxArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </button>
                    
                        <button aria-label={`${i18next.resolvedLanguage === 'en' ? "prostheses" : "Protheses"}`} className="boxTreatment box8" onClick={() => toggleOverlay({overlay:'prostheses'})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="prostheses" src={prosthesesImage}/>
                                {t("prostheses")}
                            </p>
                            <span className="boxArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </button>

                        <button aria-label={`${i18next.resolvedLanguage === 'en' ? "rooth treatment" : "Wortelkanaalbehandeling"}`} className="boxTreatment box9" onClick={() => toggleOverlay({overlay:'roothTreatment'})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="rooth treatment" src={roothImage}/>
                                {t("roothTreatment")}
                            </p>
                            <span className="boxArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </button>

                        <button aria-label={`${i18next.resolvedLanguage === 'en' ? "implants" : "Implantaten"}`} className="boxTreatment box10" onClick={() => toggleOverlay({overlay:'implants'})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="implants" src={implantsImage}/>
                                {t("implants")}
                            </p>
                            <span className="boxArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </button>

                        <button aria-label={`${i18next.resolvedLanguage === 'en' ? "Teeth Grinding and Teeth Clenching" : "Tandenknarsen en tanden klemmen"}`} className="boxTreatment box11" onClick={() => toggleOverlay({overlay:'grindingClenching'})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="grinding" src={grindingImage}/>
                                {t("grindingClenching")}
                                </p>
                            <span className="boxArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </button>

                        <button aria-label={`${i18next.resolvedLanguage === 'en' ? "hygiene" : "Mondhygiëne"}`} className="boxTreatment box12" onClick={() => toggleOverlay({overlay:'hygiene'})}>
                            <p className="h6 boxTreatmentTitle">
                                <img className="boxImage" alt="hygiene" src={hygieneImage}/>
                                {t("hygiene")}
                            </p>
                            <span className="boxArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </button>
                
                    </div>
                    
                </div>

            </div>
        </div>
    )
}

export default Treatments;