import { useTranslation } from 'react-i18next';
const OverlayHygiene = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div className="overlaySubtitle">
                {t('hygieneSub1')}
            </div>
            <div className="overlayText">
                {t('hygieneText1')}
            </div>
            <div className="overlaySubtitle">
                {t('hygieneSub2')}
            </div>
            <div className="overlayText">
                {t('hygieneText2')}
            </div>
            <div className="overlayText">
                {t('hygieneText3')}
            </div>
            <div className="overlayText">
                {t('hygieneText4')}
            </div>
        </div>
    )
}

export default OverlayHygiene;