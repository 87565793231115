import { useTranslation } from 'react-i18next';
const OverlayRooth = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div className="overlayText">
                {t('roothTreatmentText')}
            </div>
        </div>
    )
}

export default OverlayRooth;