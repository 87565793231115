import { useTranslation } from 'react-i18next';
const OverlayCrowns = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div className="overlayText">
                {t('crownsBridgesText1')}
            </div>
            <div className="overlayText">
                {t('crownsBridgesText2')}
            </div>
            <div className="overlaySubtitle">
                {t('crownsBridgesSub1')}
            </div>
            <div className="overlayText">
                {t('crownsBridgesText3')}
            </div>
            <div className="overlaySubtitle">
                {t('crownsBridgesSub2')}
            </div>
            <div className="overlayText">
                {t('crownsBridgesText4')}
            </div>
        </div>
    )
}

export default OverlayCrowns;