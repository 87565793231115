import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import SignupModal from "./SignupModal";
import firstVisit from '../../Assets/Images/firstv.webp';
import rates from '../../Assets/Images/tarife.webp';
import hours from '../../Assets/Images/hours.webp';
import complaints from '../../Assets/Images/complaint.webp';
import insurance from '../../Assets/Images/insurance.webp';
import cancel from '../../Assets/Images/cancel.webp';
import infomedics from '../../Assets/Images/infomedics.webp';
import { Button } from "react-bootstrap";
import AppointmentModal from "./AppointmentModal";
import { Link } from 'react-scroll';
import map from '../../Assets/Images/map3.webp';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

const InfoCarousel = () => {
    const { t } = useTranslation();

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        initialSlide: 0,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "15px",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
              },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
      };
    return (
        <Slider {...settings}>
            <div>
                <div className="infoDetailsSecondary">
                    <img className="infoImage" src={firstVisit} alt="first visit"/>
                    <div className="infoCarouselContainer">
                      <p className="infoDetailsTitle">{t('firstVisit')}</p>
                      <p className="secondaryExplanation">{t('firstVisitText')}</p>
                      <div className="secondaryActionButton">
                          <SignupModal type='secondary'/>
                      </div>
                    </div>
                    
                </div>
            </div>
            <div>
                <div className="infoDetailsSecondary">
                    <img className="infoImage" src={rates} alt="rates"/>
                    <div className="infoCarouselContainer">
                        <p className="infoDetailsTitle">{t('rates')}</p>
                        <p className="secondaryExplanation">{t('ratesText')}</p>
                        <div className="secondaryActionButton">
                            <Button onClick={() => { window.open("https://www.allesoverhetgebit.nl/alles-over-kosten-en-vergoedingen/tarieven-en-codes", "_blank") } } aria-label={`${i18next.resolvedLanguage === 'en' ? "view rates" : "zie tarieven"}`} className="secondaryActionButtonDark">{t('viewRates')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="infoDetailsSecondary">
                    <img className="infoImage" src={insurance} alt="no insurance"/>
                    <div className="infoCarouselContainer">
                        <p className="infoDetailsTitle">{t('noInsurance')}</p>
                        <p className="secondaryExplanation">{t('noInsuranceText')}</p>
                        <div className="secondaryActionButton">
                            <Button onClick={() => { window.open("https://www.allesoverhetgebit.nl/alles-over-kosten-en-vergoedingen/tarieven-en-codes", "_blank") } } aria-label={`${i18next.resolvedLanguage === 'en' ? "view rates" : "zie tarieven"}`} className="secondaryActionButtonDark">{t('viewRates')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="infoDetailsSecondary">
                    <img className="infoImage" src={infomedics} alt="ninfomedics"/>
                    <div className="infoCarouselContainer">
                        <p className="infoDetailsTitle">{t('infomedics')}</p>
                        <p className="secondaryExplanation">{t('infomedicsText')}</p>
                        <div className="secondaryActionButton">
                            <Button onClick={() => { window.open("https://www.infomedics.nl/", "_blank") } } aria-label={`${i18next.resolvedLanguage === 'en' ? "more info about infomedics" : "meer informatie over infomedics"}`} className="secondaryActionButtonDark">{t('infomedicsMore')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="infoDetailsSecondary">
                    <img className="infoImage" src={cancel} alt="cancel appointment"/>
                    <div className="infoCarouselContainer">
                        <p className="infoDetailsTitle">{t('cancelAppointment')}</p>
                        <p className="secondaryExplanation">{t('cancelAppointmentText')}</p>
                        <div className="secondaryActionButton">
                        <Link 
                          className="contactLink secondaryActionButtonDark" 
                          activeClass="active" 
                          to="contact" 
                          spy={true} 
                          smooth={true} 
                          offset={50} 
                          duration={500} 
                      >
                          {t("contactInfo")}
                      </Link>
                            </div>
                    </div>
                </div>
            </div>
            <div>
            <div className="infoDetailsSecondary">
                        <img className="infoImage" src={hours} alt="opening hours"/>
                        <div className="infoCarouselContainer">
                          <p className="infoDetailsTitle">{t('openingHours')}</p>
                          <p className="secondaryExplanation">
                              <span>{t('monThu')}: </span>
                                <span > 8.30 - 12.30, 13.30 - 17.00</span>

                              <span>{t("friday")}: </span>
                                <span> 8.30 - 12.00</span>
                          </p>
                          <AppointmentModal/>
                        </div>
                    </div>
            </div>
            <div>
                <div className="infoDetailsSecondary">
                    <img className="infoImage" src={complaints} alt="complaints"/>
                    <div className="infoCarouselContainer">
                      <p className="infoDetailsTitle">{t('complaints')}</p>
                      <p className="secondaryExplanation">{t('complaintsText')}</p>
                      <Link 
                          className="contactLink secondaryActionButtonDark" 
                          activeClass="active" 
                          to="contact" 
                          spy={true} 
                          smooth={true} 
                          offset={50} 
                          duration={500} 
                      >
                          {t("contactInfo")}
                      </Link>
                    </div>
                </div>
            </div>
            <div>
            <div className="infoDetailsSecondary">
                    <img className="infoImage" src={map} alt="map"/>
                    <div className="infoCarouselContainer">
                      <p className="infoDetailsTitle">{t("mapTitle")}</p>
                      <p className="secondaryExplanation">{t("mapText")}</p>
                      <a  aria-label={`${i18next.resolvedLanguage === 'en' ? "open google maps" : "open google maps pagina"}`}  className="contactLink secondaryActionButtonDark" rel="noreferrer" href="https://maps.app.goo.gl/hLxPkC5V8HPMxGZu6" target="_blank">{t("googleMaps")}</a>
              </div>
                </div>
            </div>
    </Slider>
    )
}

export default InfoCarousel;