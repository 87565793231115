export const service = () => {
    return atob('c2VydmljZV8ycHV1dWdo');
  }
  
export const templateApp = () => {
    return atob('dGVtcGxhdGVfYnQ1ZjJjZA==')
  }
  
export const profile = () => {
    return atob('cHRNNjhrZi1jdzA2Ymp3TEk=')
  }

export const templateSign = () => {
    return atob('dGVtcGxhdGVfMnAzOHU4cQ==')
  }