import { useTranslation } from 'react-i18next';

const OverlayAligners = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div className="overlayText">
                {t("alignersText1")}
            </div>
            <div className="overlaySubtitle">
                {t("alignersSub1")}
            </div>
            <div className="overlayText">
                {t("alignersText2")}
            </div>
            <div className="overlayText">
                {t("alignersText3")}
            </div>
            <div className="overlayText">
                {t("alignersText4")}
            </div>
            <div className="overlayText">
                {t("alignersText5")}
            </div>
            <div className="overlayText">
                {t("alignersText6")}
            </div>
        </div>
    )
}

export default OverlayAligners;