import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../Assets/Images/logo4.webp';
import { Image } from 'react-bootstrap';
import { useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import EmergencyModal from '../organs/EmergencyModal';
import i18next from 'i18next';

const HeaderMenu = () => {
    const { t, i18n } = useTranslation();
    const [expanded, setExpanded] = useState(false);
  

      const scrollToTop = () => {
        scroll.scrollToTop();
      };

      const handleHome = () => {
        setExpanded(false);
        scrollToTop();
      }

    const [currentLanguage, setCurrentLanguage] = useState('nl');
    const changeLanguage = (lang: string) =>{
      setCurrentLanguage(lang);
    }

    return (
      <Navbar expanded={expanded} collapseOnSelect={true} expand="lg" data-bs-theme="light" sticky="top" className="fixed-top">
       <Container>
          <Navbar.Brand href="/">
            <div className="brandContainer">
              <Image src={logo} alt="Tandartspraktijk Valderstraat" className="brandImage"/>
            
              <div className="logoName navigationLink">
                <span className="practiceDescription">{t('dentalPractice')}</span>
                <span className="practiceName">VALDERSTRAAT</span>
              </div>
            </div>
             
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)}/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navigation justify-content-center align-items-center" style={{ width: "100%" }}>
                <a className="navigationLink linkNav" href="/" aria-label={`${i18next.resolvedLanguage === 'en' ? "home" : "home pagina"}`} onClick={()=> handleHome()}>Home</a>
                <Link href="#" className="navigationLink linkNav" to="section2" smooth={true} duration={500} onClick={() => setExpanded(false)}>{t("practice")}</Link>
                <Link href="#" className="navigationLink linkNav" to="section3" smooth={true} duration={500} onClick={() => setExpanded(false)}>{t("treatments")}</Link>
                <Link href="#" className="navigationLink linkNav" to="section4" smooth={true} duration={500} onClick={() => setExpanded(false)}>{t("information")}</Link>
                <Link href="#" className="navigationLink linkNav" to="section5" smooth={true} duration={500} onClick={() => setExpanded(false)}>{t("contact")}</Link>
                <EmergencyModal/>
                {currentLanguage === 'en' && <a href="#" className="navigationLink navLanguage linkNav" onClick={()=> {changeLanguage('nl'); i18n.changeLanguage('nl'); setExpanded(false)}}>NL</a>}
                {currentLanguage === 'nl' &&  <a href="#" className="navigationLink navLanguage linkNav" onClick={()=>{changeLanguage('en'); i18n.changeLanguage('en'); setExpanded(false)}}>EN</a>}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end d-none d-lg-block">
            <div className="headerCallSection">
                <span className="headerCallPhone">+31 46 433 65 52</span>
                <a href="#" aria-label={`${i18next.resolvedLanguage === 'en' ? "call us" : "bell ons"}`} className="callButtonHeader navigationSubLink" href="tel:+31464336552"> {t("call us")} </a>
            </div>
          </Navbar.Collapse>
          </Container>
      </Navbar>
    );
  }
  
  export default HeaderMenu;