import i1 from '../../Assets/Images/image1.webp';
import i2 from '../../Assets/Images/image2.webp';
import i3 from '../../Assets/Images/image3.webp';
import i4 from '../../Assets/Images/image4.webp';
import i5 from '../../Assets/Images/image5.webp';
import i6 from '../../Assets/Images/image6.webp';
import i7 from '../../Assets/Images/image7.webp';
import i8 from '../../Assets/Images/image8.webp';
import Slider from "react-slick";

const ImageSwiper = () => {
    const sliderSetting = {
        className: "imageSwiper",
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                }
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
              },
              
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1.2,
                  slidesToScroll: 0.8,
                }
              },
        ]
      };

    return (
        <Slider {...sliderSetting}>
        <div>
            <img className="swiperImage"  src={i1} alt="praktijk"/>
        </div>
        <div>
        <img className="swiperImage"  src={i2} alt="praktijk"/>
        </div>
        <div>
        <img  className="swiperImage" src={i3} alt="praktijk"/>
        </div>
        <div>
        <img className="swiperImage"  src={i4} alt="praktijk"/>
        </div>
        <div>
        <img className="swiperImage" src={i5} alt="praktijk"/>
        </div>
        <div>
        <img className="swiperImage"  src={i6} alt="praktijk"/>
        </div>
        <div>
        <img className="swiperImage"  src={i7} alt="praktijk"/>
        </div>
        <div>
        <img className="swiperImage"  src={i8} alt="praktijk"/>
        </div>
    </Slider>
    )
}

export default ImageSwiper;