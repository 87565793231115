import { useTranslation } from 'react-i18next';
const OverlayCosmetic = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div className="overlaySubtitle">
                {t('cosmeticDentistrySub1')}
            </div>
            <div className="overlayText">
                {t('cosmeticDentistryText1')}
            </div>
            <div className="overlaySubtitle">
                {t('cosmeticDentistrySub2')}
            </div>
            <div className="overlayText">
                {t('cosmeticDentistryText2')}
            </div>
            <div className="overlaySubtitle">
                {t('cosmeticDentistrySub3')}
            </div>
            <div className="overlayText">
                {t('cosmeticDentistryText3')}
            </div>
            <div className="overlaySubtitle">
                {t('cosmeticDentistrySub4')}
            </div>
            <div className="overlayText">
                {t('cosmeticDentistryText4')}
            </div>
        </div>
    )
}

export default OverlayCosmetic;