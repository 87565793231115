import { useTranslation } from 'react-i18next';
const OverlayWhite = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div className="overlayText">
                {t('whitheningText1')}
            </div>
            <div className="overlaySubtitle">
                {t('whitheningSub1')}
            </div>
            <div className="overlayText">
                {t('whitheningText2')}
            </div>
        </div>
    )
}

export default OverlayWhite;