import { useTranslation } from 'react-i18next';

const FeedbackMessage = () => {
    const { t } = useTranslation();

    return(
        <div>{t("feedbackContact")}</div>
    )
}

export default FeedbackMessage;