import { useTranslation } from 'react-i18next';

const FeedbackAppointment = () => {
    const { t } = useTranslation();

    return(
        <div className="feedbackContainer">
            <div className="feedbackModal">{t("feedbackAppointment")}</div>
        </div>
        
    )
}

export default FeedbackAppointment;