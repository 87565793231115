import { useTranslation } from 'react-i18next';
const OverlayAnxious = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div className="overlaySubtitle">
                {t("anxiousPatientsSub1")}
            </div>
            <div className="overlayText">
                {t("anxiousPatientsText1")}
            </div>
            <div className="overlaySubtitle">
                {t("anxiousPatientsSub2")}
            </div>
            <div className="overlayText">
                {t("anxiousPatientsText2")}
            </div>
        </div>
    )
}

export default OverlayAnxious;