import { useTranslation } from 'react-i18next';
const OverlayGrinding = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div className="overlayText">
                {t('grindingClenchingText1')}
            </div>
            <div className="overlaySubtitle">
                {t('grindingClenchingSub1')}
            </div>
            <div className="overlayText">
                {t('grindingClenchingText2')}
            </div>
            <div className="overlayText">
                {t('grindingClenchingText3')}
            </div>
        </div>
    )
}

export default OverlayGrinding;