import { Container } from "react-bootstrap";
import InfoCarousel from "./InfoCarousel";

const InfoSection = () => {
    return(
        <div id="information">
            <Container>
                <InfoCarousel/>
            </Container>
        </div>
    )
}

export default InfoSection;