import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const CookiesContent = () => {
        const { t } = useTranslation();
        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

    return (
        <>
        <Button aria-label={`${i18next.resolvedLanguage === 'en' ? "cookies and privacy" : "cookies en privacyverklaring"}`} className="cookiesButton" variant="outline" size="sm" onClick={handleShow}>
                Cookies & privacy
        </Button>
            <Modal show={show} fullscreen onHide={handleClose}>
                 <Modal.Header closeButton>
                        <Modal.Title>{t('privacyCookies')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p className="cookiesModal">
                        <p>
                                Uw privacy wordt door ons gerespecteerd. Tandartspraktijk Valderstraat streeft ernaar om uw privacy zo goed mogelijk te waarborgen en zal vertrouwelijk omgaan met de informatie die u bij ons aanlevert. Bij de verwerking van persoonsgegevens nemen wij de geldende wet- en regelgeving op het gebied van privacy in acht. In deze privacy- en cookieverklaring informeren wij u over de wijze waarop wij met uw gegevens omgaan.
                        </p>
                        <p>
                                Door het gebruiken van de website en de daarop beschikbare diensten laat u bepaalde gegevens bij ons achter. Dat gebeurt ook bij ons in de praktijk in het kader van de uitvoering van de behandelovereenkomst. Dat kunnen persoonsgegevens zijn. Wij bewaren en gebruiken uitsluitend de persoonsgegevens die rechtstreeks door u worden opgegeven of waarvan bij opgave duidelijk is dat ze aan ons worden verstrekt om te verwerken.
                        </p>
                        <p>
                                Afhankelijk van de dienst die u gebruikt kunnen wij de volgende gegevens verzamelen:
                                <ul>
                                        <li>NAW gegevens;</li>
                                        <li>Geslacht;</li>
                                        <li>E-mailadres;</li>
                                        <li>Telefoonnummer;</li>
                                        <li>BSN/Sofinummer;</li>
                                        <li>Gegevens betreffende uw gezondheid;</li>
                                        <li>De naam en polisnummer van uw zorgverzekeraar;</li>
                                        <li>De naam van uw andere zorgverleners; Zoals huisarts of specialist;</li>
                                        <li>Tijdstip van uw afspraak;</li>
                                        <li>Betalingsgegevens.</li>
                                </ul>
                        </p>
                        <h6>
                        Grondslag voor gegevensverwerking
                        </h6>
                        <p>
                        Wij mogen alleen rechtmatig persoonsgegevens van u verwerken als wij dat doen op basis van een juridische grondslag. Wij verwerken uw persoonsgegevens omdat dit noodzakelijk is voor het uitvoeren van de overeenkomst tussen u en ons, zoals neergelegd in artikel 6 lid 1 sub b van de Algemene Verordening Gegevensbescherming (AVG). Daarnaast kunnen wij uw persoonsgegevens verwerken voor een ander gerechtvaardigd belang, zoals het informeren van onze patiënten over actualiteiten of wijzigingen in onze dienstverlening. Deze grondslag is neergelegd in artikel 6 lid 1 sub f van de AVG.
                        </p>
                        <h6>
                        Doeleinden gegevensverwerking
                        </h6>
                        <p>
                                De persoonsgegevens die door ons worden verzameld, worden gebruikt voor de volgende doeleinden:

                                <ul>
                                        <li>Het aanmaken van een patiëntenkaart om u in te schrijven bij de praktijk;</li>
                                        <li>Het bijhouden van uw medisch dossier;</li>
                                        <li>Het inplannen van een afspraak;</li>
                                        <li>Het uitvoeren van een behandeling;</li>
                                        <li>Het bijhouden van door u aangegeven voorkeuren;</li>
                                        <li>Het verbeteren van onze dienstverlening;</li>
                                        <li>Het uitvoeren van overige, door u gevraagde, diensten;</li>
                                        <li>Het uitvoeren van patiënttevredenheidsonderzoek;</li>
                                        <li>Het, indien nodig, beveiligd (retour) versturen van uw tandheelkundige gegevens aan een externe verwijzer.</li>
                                </ul>
                        </p>
                        <h6>
                        Verstrekking van uw persoonsgegevens aan derden
                        </h6>
                        <p>
                        Wij verstrekken uw persoonsgegevens in beginsel alleen aan derde partijen indien u daar zelf toestemming voor heeft gegeven. Gegevensverstrekking zonder uw toestemming vindt plaats indien dat noodzakelijk is om de overeenkomst tussen u en ons uit te kunnen voeren of een wettelijke of beroepsmatige verplichting ons dat voorschrijft.
                        </p>
                        <h6>
                        Bewaartermijnen
                        </h6>
                        <p>
                        Wij zullen uw gegevens niet langer bewaren dan noodzakelijk is voor de in deze privacy- en cookieverklaring beschreven doeleinden, tenzij dat nodig is op grond van een wettelijke verplichting. Voor het bewaren van uw medische gegevens hanteren wij de wettelijke bewaartermijn van 15 jaar uit de Wet op de geneeskundige behandelovereenkomst. Naast de WGBO houden we indien nodig ook rekening met bewaartermijnen die voortvloeien uit andere wetgeving.
                        </p>
                        <h6>
                        Beveiligingsmaatregelen
                        </h6>
                        <p>
                        Om uw gegevens zo goed mogelijk te beschermen hebben wij passende beveiligingsmaatregelen getroffen.
                        Er is een intern privacy beleid opgesteld en geïmplementeerd; Hierin staan afspraken hoe de praktijk omgaat met o.a. persoonsgegevens van zowel patiënten als ook werknemers.
                        De praktijk werkt met verwerkersovereenkomsten; De praktijk maakt gebruik van diensten van derden (denk aan het tandheelkundig informatiesysteem), deze worden gezien als een ”verwerker”. In deze overeenkomsten worden specifieke afspraken gemaakt over de omvang van persoonsgegevens.
                        Indien sprake is van een datalek wordt dit gemeld bij de autoriteit persoonsgegevens. Intern wordt er altijd gekeken hoe het datalek heeft kunnen plaatsvinden en hoe dit te voorkomen.
                        </p>
                        <h6>
                        Verwijzingen naar websites van derden (via hyperlinks)
                        </h6>
                        <p>
                        Om u van dienst te zijn hebben wij op onze website verwijzingen opgenomen naar websites van derden. Wij maken u er graag op attent dat wanneer u deze websites bezoekt, de voorwaarden uit de privacy verklaringen van deze derden van toepassing zijn. Wij raden u aan om de privacy verklaringen van deze websites te lezen voordat u daar verder gebruik van maakt.
                        </p>
                        <h6>
                        Het gebruik van cookies
                        </h6>
                        <p>
                        Tandartspraktijk Valderstraat gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.
                        </p>
                        <h6>
                        Uw rechten
                        </h6>
                        <p>
                        Wanneer u persoonsgegevens aan ons heeft verstrekt, heeft u verschillende rechten die u kunt uitoefenen. Zo heeft u recht op inzage, rectificatie en het wissen van uw gegevens. Ook kunt u ons verzoeken om uw gegevens aan u of een andere partij over te dragen of om de gegevensverwerking te beperken. Het staat u daarnaast vrij om bezwaar te maken tegen een verwerking van uw gegevens. U kunt bovendien uw toestemming voor de gegevensverwerking altijd in trekken. U kunt uw verzoek bij ons kenbaar maken door ons een e-mail te sturen of door telefonisch contact met ons op te nemen via: 046 433 6552. Wettelijk gezien dient de praktijk binnen één maand na ontvangst van het verzoek te reageren. Echter ons streven is, indien mogelijk, eerder op uw verzoek te reageren.
                        </p>
                        <h6>
                        Klacht indienen bij de Autoriteit persoonsgegevens
                        </h6>
                        <p>
                        Mocht u onverhoopt niet tevreden zijn over de wijze waarop wij met uw gegevens omgaan,

                        dan kunt u uw klacht telefonisch (046 433 6552) of per e-mail aan ons kenbaar maken. Wij zullen dan zo snel mogelijk contact met u opnemen om uw klacht te bespreken.

                        Mocht u er onverhoopt toch niet uitkomen met de praktijk dan kunt u een klacht hierover indienen bij de Autoriteit persoonsgegevens. De contactgegevens van de Autoriteit persoonsgegevens vindt u hier: https://autoriteitpersoonsgegevens.nl/nl/zelf-doen/privacyrechten/klacht-over-gebruik-persoonsgegevens.
                        </p>
                        <p>
                                <h6>
                                Wijzigingen in deze privacy statement en cookieverklaring
                                </h6>
                                <p>
                                Wij behouden ons het recht voor om deze privacy statement en cookieverklaring) aan te passen. Deze wijzigingen zullen via onze praktijkwebsite bekend worden gemaakt. Wij raden u daarom aan om deze verklaring regelmatig te raadplegen, zodat u van eventuele wijzigingen op de hoogte bent.
                                </p>
                        </p>
                        <h6>
                        Onze contactgegevens
                        </h6>
                        <p>
                        Heeft u nog vragen of opmerkingen na het lezen van dit privacy statement? Dan kunt u hiervoor contact met ons opnemen via de onderstaande contactgegevens:
                        </p>
                        <p>
                        Tandartspraktijk Valderstraat
                        </p>
                        <p>
                        Telnr.: 046 433 6552
                        </p>
                </p>
                </Modal.Body>
                <Modal.Footer>
                        <Button aria-label={`${i18next.resolvedLanguage === 'en' ? "close" : "sluiten"}`} variant="outline" onClick={handleClose}>
                                {t('close')}
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
  }
  
  export default CookiesContent;