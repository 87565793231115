import { useTranslation } from 'react-i18next';

const FeedbackSignUp = () => {
    const { t } = useTranslation();

    return(
        <div className="feedbackContainer">
            <div className="feedbackModal">{t("feedbackSignup")}</div>
        </div>
    )
}

export default FeedbackSignUp;