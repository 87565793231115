import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './components/species/HeaderMenu.css';
import './components/species/Content.css';
import './components/organs/Modal.css';
import './components/species/Footer.css';
import './components/organs/Contact.css';
import  './components/organs/ContactSection.css';
import './components/organs/InfoSection.css';
import './components/organs/AboutSection.css';
import './components/organs/InfoCarousel.css';
import './components/species/CookiesContent.css';
import './components/organs/Loading.css';
import "animate.css/animate.compat.css";
import "./components/organs/EmergencyModal.css";
import './components/organs/Treatments.css';
import './components/cells/Overlays.css';
import './components/organs/TeamSection.css';
import './components/cells/TeamMember.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
