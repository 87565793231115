import { useTranslation } from 'react-i18next';
const OverlayImplants = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div className="overlayText">
                {t('implantsText1')}
            </div>
            <div className="overlaySubtitle">
                {t('implantsSub1')}
            </div>
            <div className="overlayText">
                {t('implantsText2')}
            </div>
            <div className="overlayText">
                {t('implantsText3')}
            </div>
            <div className="overlayText">
                {t('implantsText4')}
            </div>
        </div>
    )
}

export default OverlayImplants;