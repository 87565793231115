import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const TermsConditions = () => {
        const { t } = useTranslation();
        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

    return (
        <>
        <Button aria-label={`${i18next.resolvedLanguage === 'en' ? "terms and conditions" : "Algemene voorwaarden"}`} className="cookiesButton" variant="outline" size="sm" onClick={handleShow}>
                {t('termsConditions')}
        </Button>
            <Modal show={show} fullscreen onHide={handleClose}>
                 <Modal.Header closeButton>
                        <Modal.Title>{t('terms')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {i18next.resolvedLanguage === 'nl' &&
                                <p className="cookiesModal">
                                <h6>De Overeenkomst</h6>
                                <p>
                                Deze algemene voorwaarden maken een onlosmakelijk onderdeel uit van de behandelovereenkomst op grond van artikel 7:446 lid 1 BW tussen Tandartspraktijk Valderstraat en de cliënt, hierna te noemen de Overeenkomst.
                                </p>

                                <h6>Uitvoering van de Overeenkomst</h6>
                                <p>
                                Tandartspraktijk Valderstraat staat er voor in dat de werkzaamheden die zij uit hoofde van deze Overeenkomst verricht voldoen aan de maatstaven die een goed hulpverlener in acht dient te nemen en in overeenstemming zijn met de wettelijke bepalingen (waaronder de WGBO) alsmede met de algemeen geldende richtlijnen.
                                Tandheelkundige behandelingen vallen onder medische/geneeskundige behandelingen. De (tand)arts heeft een inspanningsverbintenis en geen resultaatverbintenis.
                                Tandartspraktijk Valderstraat is bij uitvoering van de Overeenkomst gerechtigd derden in te schakelen. Tandartspraktijk Valderstraat is voor gedragingen van deze derden niet aansprakelijk.
                                Cliënt dient te allen tijde Tandartspraktijk Valderstraat volledig en waarheidsgetrouw te informeren inzake de algemene gezondheid van de cliënt en inzake omstandigheden die van invloed kunnen zijn op de behandeling.
                                </p>
                                                <h6>Kosten</h6>
                                                <p>
                                De kosten van een tandheelkundige behandeling worden gedeclareerd overeenkomstig de geldende UTP tarieven zoals die door de NZa zijn vastgesteld en zijn vastgelegd in de tariefsbeschikking tandheelkundige zorg (www.NZa.nl).
                                De tarievenlijst tandheelkundige zorg is te raadplegen op de in de aanhef genoemde websites van Tandartspraktijk Valderstraat. De tarieven kunnen van tijd tot tijd worden aangepast.
                                Voor alle behandelingen die een totaalbedrag van EUR 250,- of meer bedragen, verstrekt Tandartspraktijk Valderstraat voorafgaand aan de behandeling een begroting.
                                Bij noodzakelijke wijziging van de behandeling als gevolg van onvoorziene complicaties tijdens de behandeling, die een overschrijding van de begroting tot gevolg hebben, zullen na overleg met de cliënt, de extra te maken kosten bij cliënt in rekening worden gebracht.
                                Techniekkosten worden apart in rekening gebracht. Op verzoek van cliënt of diens verzekeraar overlegt Tandartspraktijk Valderstraat hiervan de specificatie.
                                </p>
                                                <h6>Annuleren van afspraken</h6>
                                                <p>
                                Vastgestelde afspraken kunnen door cliënt kosteloos worden verzet of geannuleerd tot maximaal 48 uur voor het geplande tijdstip. Bij het niet of niet tijdig verzetten of annuleren van de afspraak zal Tandartspraktijk Valderstraat de gereserveerde tijd en/of afgesproken behandeling met code C90 in rekening brengen waarbij rekening wordt gehouden met de duur van de geplande behandeling, waarvoor wordt verwezen naar de tarievenlijst welke te vinden is op de in de aanhef genoemde websites.
                                Tandartspraktijk Valderstraat is te allen tijde bevoegd om vastgestelde afspraken met de cliënt te verzetten of te annuleren.
                                </p>
                                                <h6>Annuleren van behandeling</h6>
                                                <p>
                                Indien cliënt, nadat een behandelovereenkomst tot stand is gekomen, de behandeling geheel of gedeeltelijke annuleert, is Tandartspraktijk Valderstraat gerechtigd om alle in verband met die behandeling door haar en door derden gemaakte kosten geheel bij de cliënt in rekening te brengen.
                                </p>
                                                <h6>Declaratie</h6>
                                                <p>
                                Infomedics verzorgt voor Tandartspraktijk Valderstraat de declaraties. Hierop zijn de Infomedics betalingsvoorwaarden van toepassing. Deze betalingsvoorwaarden zijn gedeponeerd bij de Kamer van Koophandel te Gooi en Eemland onder nummer 04048143. De betalingsvoorwaarden liggen tevens ter inzage bij Tandartspraktijk Valderstraat, staan op de website van Tandartspraktijk Valderstraat en zijn beschikbaar bij Infomedics (www.infomedics.nl).
                                Indien nota’s door Infomedics wegens betalingsachterstand van de cliënt niet meer worden geaccepteerd, is Tandartspraktijk Valderstraat gerechtigd verdere behandeling op te schorten, of deze slechts te verrichten tegen directe betaling door cliënt.
                                </p>
                                                <h6>Reclame</h6>
                                                <p>
                                Klachten over de verrichte werkzaamheden of de factuur dienen door de cliënt binnen 30 dagen na ontdekking, doch uiterlijk binnen drie maanden na voltooiing van de betreffende werkzaamheden, schriftelijk te worden gemeld aan Tandartspraktijk Valderstraat.
                                Een klacht, als in het eerste lid bedoeld, schort de betalingsverplichting van de cliënt niet op.
                                De klacht zal door Tandartspraktijk Valderstraat worden behandeld volgens de Klachtenregeling van de Koninklijke Nederlandse Maatschappij tot bevordering der Tandheelkunde.
                                Als een klacht gegrond is en herstel mogelijk is, zal Tandartspraktijk Valderstraat de werkzaamheden alsnog verrichten, zoals overeengekomen.
                                </p>
                                                <h6>Aansprakelijkheid</h6>
                                                <p>
                                Rechten op vergoeding wegens aansprakelijkheid, verlopen na 1 jaar nadat het schadeveroorzakende feit zich heeft voorgedaan.
                                Cliënt kan geen rechten ontlenen aan de berekening gemaakt door Zorgsom.
                                </p>
                                                <h6>Toepasselijk recht</h6>
                                                <p>
                                Op alle overeenkomsten tussen cliënt en Tandartspraktijk Valderstraat is het Nederlands recht van toepassing.
                                Alle geschillen die verband houden met de Overeenkomst tussen cliënt en Tandartspraktijk Valderstraat, waarop deze algemene voorwaarden van toepassing zijn, worden beslecht door de rechter in Amsterdam.
                                </p>
                                                <h6>Overige bepalingen</h6> 
                                                <p>
                                                        
                                Indien enige bepaling van deze algemene voorwaarden nietig of vernietigbaar is, laat dit de geldigheid van de overige bepalingen onverlet.
                                </p>
                                </p> 
                                }
                        {i18next.resolvedLanguage === 'en' &&
                                <p className="cookiesModal">
                                        <h6>The Agreement</h6>
                                        <p>These general terms and conditions form an integral part of the treatment agreement pursuant to
                                        Section 7:446, subsection 1 of the Dutch Civil Code [Burgerlijk Wetboek (WB)] between Tandartspraktijk Valderstraat and the
                                        client, hereinafter referred to as the Agreement. </p>
                                        <h6>
                                        Performance of the Agreement
                                        </h6>
                                        <p>
                                        1. Tandartspraktijk Valderstraat guarantees that the work it undertakes pursuant to this Agreement meets the standards to be
                                        observed by a prudent care provider and that it complies with the statutory provisions (including the
                                        Medical Treatment Contracts Act [Wet op de geneeskundige behandelingsovereenkomst (WGBO)] as
                                        well as the generally prevailing guidelines.
                                        2. Dental treatment falls under medical treatment. The dentist or physician has a best-effort obligation,
                                        not a result obligation.
                                        3. Tandartspraktijk Valderstraat is entitled to engage third parties for the performance of the Agreement. Tandartspraktijk Valderstraat is not liable for
                                        the conduct of these third parties.
                                        4. The client must always provide Tandartspraktijk Valderstraat with full and truthful information regarding their general health
                                        and regarding circumstances that may affect the treatment. 
                                        </p>
                                        <h6>
                                                Costs
                                        </h6>
                                        <p>
                                        1. The costs of dental treatment are claimed in accordance with the prevailing uniform private rates [UPT]
                                        determined by the Dutch Healthcare Authority [Nederlandse Zorgautoriteit (NZa)] and set out in the
                                        tariff decision for dental care (www.NZa.nl).
                                        2. The list of rates for dental care can be found on the Tandartspraktijk Valderstraat websites referred to in the introduction.
                                        These rates may be reviewed from time to time.
                                        3. Tandartspraktijk Valderstraat will provide an advance quote for all treatment that exceeds a total amount of EUR 250.
                                        4. If the treatment needs to be changed as a result of unforeseen complications that arise during
                                        treatment and which will cause the budget to be exceeded, the additional costs incurred will be charged
                                        to the client after consultation with the client.
                                        5. Dental technician’s fees will be invoiced separately. Tandartspraktijk Valderstraat will provide an appropriate specification at
                                        the request of the client or their insurer
                                        </p>
                                        <h6>
                                        Cancellation of appointments
                                        </h6>
                                        <p>
                                        1. The client can rebook or cancel confirmed appointments free of charge up to 48 hours prior to the
                                        scheduled appointment. When the appointment is not rebooked or cancelled or when this is not done in
                                        time, Tandartspraktijk Valderstraat will charge the booked time and/or agreed treatment under code C90, taking the duration
                                        of the scheduled treatment into account and with reference to the price list, which can be found on the
                                        websites referred to in the introduction.
                                        2. Tandartspraktijk Valderstraat is at all times entitled to rebook or cancel confirmed appointments with the client. 
                                        </p>
                                        <h6>
                                        Cancellation of treatment
                                        </h6>
                                        <p>
                                        If the client cancels all or part of the treatment after a treatment agreement is concluded, Tandartspraktijk Valderstraat is
                                        entitled to charge all costs incurred by Tandartspraktijk Valderstraat and third parties in connection with that treatment in full. 
                                        </p>
                                        <h6>
                                        Invoices
                                        </h6>
                                        <p>
                                        1. Infomedics prepares the invoices for Tandartspraktijk Valderstraat, which invoices are subject to the Infomedics payment
                                        conditions. These payment conditions are filed with the Gooi- en Eemland Chamber of Commerce under
                                        number 04048143. The payment conditions can also be consulted at Tandartspraktijk Valderstraat, can be found on the Tandartspraktijk Valderstraat
                                        website and are available via Infomedics (www.infomedics.nl).
                                        2. If Infomedics no longer accepts bills due to the client’s payment arrears, Tandartspraktijk Valderstraat is entitled to suspend
                                        further treatment or to provide it only in return for cash payment by the client. 
                                        </p>
                                        <h6>
                                        Complaints
                                        </h6>
                                        <p>
                                        1. The client must notify Tandartspraktijk Valderstraat in writing of complaints in relation to the work carried out within 30 days
                                        of discovery, yet within no longer than three months of completion of the relevant work.
                                        2. Complaints, as referred to in paragraph 1, do not suspend the payment obligation of the client.
                                        3. Tandartspraktijk Valderstraat will process the complaint in accordance with the complaints procedure of the Royal Dutch
                                        Dental Association [Koninklijke Nederlandse Maatschappij tot bevordering der Tandheelkunde].
                                        4. When a complaint is well-founded and recovery is possible, Tandartspraktijk Valderstraat will undertake the work after all,
                                        as agreed.
                                        </p>
                                        <h6>
                                        Liability
                                        </h6>
                                        <p>
                                        1. The rights to compensation on account of liability lapse one year after the loss-making event took place.
                                        2. The client cannot derive any rights from the calculation made by Zorgsom. 
                                        </p>
                                        <h6>
                                        Applicable law
                                        </h6>
                                        <p>
                                        1. All agreements between the client and Tandartspraktijk Valderstraat are subject to Dutch law.
                                        2. All disputes in relation to the Agreement between the client and Tandartspraktijk Valderstraat which these terms and
                                        conditions apply to are settled by the court in Amsterdam. 
                                        </p>
                                        <h6>
                                        Other provisions
                                        </h6>
                                        <p>
                                        If any provision of these general terms and conditions is void or voidable, the validity of the other
                                        provisions remains unaffected.
                                        </p>
                                </p>
                        }
                </Modal.Body>
                <Modal.Footer>
                        <Button aria-label={`${i18next.resolvedLanguage === 'en' ? "close" : "sluiten"}`} variant="outline" onClick={handleClose}>
                                {t('close')}
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
  }
  
  export default TermsConditions;